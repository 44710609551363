/**
 * Javascript for InjectorFooter
 **/

function InjectorFooter() {
  this.init = () => {};

  this.inject = (html) => {
    $(".site-footer").append(html);
    $(window).trigger("injector:footer-load");
  };
}

export default InjectorFooter;
