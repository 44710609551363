/**
 * Javascript for InjectorCommerce
 **/

function InjectorCommerce() {
  this.lastThirdParagraph = (article, widget) => {
    const mainContent = article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;
    const galleryCount = mainContent.find(".gallery-slide").length;
    const commerceCount = mainContent.find(".commerce-gallery-item").length;
    const slideCount = galleryCount ? galleryCount : commerceCount; // Either gallery or commerce
    const slide = galleryCount ? ".gallery-slide" : ".commerce-gallery-item";
    // Last 3rd if there are more than 6 paragraphs/slides, or at the end of the article.
    const posFromLast = pCount + slideCount >= 6 ? 3 : 1;
    if (slideCount > posFromLast) {
      mainContent.find(`${slide}:eq(-${posFromLast})`).before(widget);
    } else {
      mainContent.find(`p:eq(-${posFromLast - slideCount})`).before(widget);
    }
  };
}

export default InjectorCommerce;

export function initCommerceSwiper() {
  let swiperContainers = document.querySelectorAll(
    '[data-js="essence-carousel-swiper"]'
  );

  swiperContainers.forEach((sliderEl) => {
    new Swiper(sliderEl, {
      effect: "coverflow",
      initialSlide: 1,
      spaceBetween: 0,
      loop: true,
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 1.3,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 125,
        modifier: 1,
        slideShadows: true,
      },
      navigation: {
        nextEl: sliderEl.parentNode.querySelector(".swiper-button-prev"),
        prevEl: sliderEl.parentNode.querySelector(".swiper-button-next"),
      },
      breakpoints: {
        // when window width is >= 768px
        768: {
          effect: "slide",
          initialSlide: 0,
          spaceBetween: 20,
          centeredSlides: false,
          slidesPerView: 3,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 1,
            slideShadows: false,
          },
          autoplay: {
            delay: 100,
          },
        },
      },
    });
  });

  const shoppingNewSipwers = document.querySelectorAll(
    '[data-js="essence-shopping-swiper-new"]'
  );

  shoppingNewSipwers.forEach((sliderEl) => {
    new Swiper(sliderEl, {
      spaceBetween: 20,
      grabCursor: true,
      slidesPerView: "auto",
      scrollbar: {
        el: sliderEl.parentNode.querySelector(".swiper-scrollbar"),
      },
      navigation: {
        nextEl: sliderEl.parentNode.querySelector(".swiper-button-next"),
        prevEl: sliderEl.parentNode.querySelector(".swiper-button-prev"),
      },
      breakpoints: {
        // when window width is >= 768px
        768: {
          spaceBetween: 24,
        },
      },
    });
  });
}

// Allow injected carousel widgets to initialize themselves
// Usage: commerceSwiper.initCommerceSwiper();
window.commerceSwiper = {
  initCommerceSwiper: initCommerceSwiper,
};
