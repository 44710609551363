/**
 * Javascript for InjectorExpandedmenu
 **/

function InjectorExpandedmenu() {
  this.init = () => {};

  this.inject = (menu) => {
    $("body").append(menu);
    $(window).trigger("injector:expandedmenu-load");
  };
}

export default InjectorExpandedmenu;
