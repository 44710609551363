/**
 * Javascript for InjectorTopnav
 **/

function InjectorTopnav() {
  this.init = () => {};

  this.inject = (topnav) => {
    if (topnav) {
      $("#site-navigation").html(topnav);
    }
  };
}

export default InjectorTopnav;
